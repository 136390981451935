import "core-js/modules/es.array.push.js";
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import * as echarts from 'echarts';
import { onMounted, ref, watch, reactive } from 'vue';

// let loading = ref(true)

export default {
  __name: 'indexEchart2',
  props: {
    xDataArray: Array,
    chartArray: Array
  },
  setup(__props) {
    const props = __props;

    // console.log('次坐标轴！！！！！！！！！！！！！！！！！！！1');
    // console.log('传过来的chartArray!!!!!!!!!!!!!!!!!!',props.chartArray)

    const chart = ref(null);
    let myChart;
    const maxVal = ref(0); // 最大
    const minVal = ref(0); // 最小
    let Amax = ref(0); // 30A指数的最大值
    let Amin = ref(0); // 30A指数的最小值

    let chartMaxArr = ref([]); // 数据的最大值
    let charMax = ref(0);
    let chartMinArr = ref([]); // 数据的最小值
    let charMin = ref(0);
    let chartNewData = reactive([]);
    onMounted(() => {
      getChart();
    });
    watch(() => props.xDataArray, n => {
      getChart(n, props.chartArray);
    });
    watch(() => props.chartArray, m => {
      // console.log('chartArray改变咯~~~~~~~~~~~~~~~~~~',m);

      getChart(props.xDataArray, m);
      getMaxYData(m);
      getMinYData(m);
    });
    const getMaxYData = m => {
      // 最大值
      chartMaxArr.value = [];
      if (m.length == 1) {
        // 只有一条数据时
        Object.keys(m).forEach(item => {
          chartMaxArr.value.push(Math.max(...m[item].valuedata));
          charMax.value = Math.max(...chartMaxArr.value);
          // maxVal.value = Math.ceil(charMax.value)
          maxVal.value = parseFloat(charMax.value) + parseFloat(0.1);
        });
      } else {
        // 多条数据时
        m.map(item => {
          if (item.zpIndexId == 1) {
            // console.log('我是30A的最大值',Math.max(...item.valuedata));
            // Amax.value = Math.ceil(Math.max(...item.valuedata))
            Amax.value = parseFloat(Math.max(...item.valuedata)) + parseFloat(0.03);
          } else {
            chartMaxArr.value.push(Math.max(...item.valuedata));
            charMax.value = Math.max(...chartMaxArr.value);
            maxVal.value = Math.ceil(charMax.value);
            // maxVal.value = parseFloat(charMax.value) + parseFloat(0.3)
            // console.log('2222222',charMax.value);
            // console.log('最大值是',maxVal.value);
          }
        });
      }
    };

    const getMinYData = m => {
      // 最小值
      // console.log('最小值咯!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',m.length);
      chartMinArr.value = [];
      if (m.length == 1) {
        // console.log('最小值是',m);
        Object.keys(m).forEach(item => {
          chartNewData = [];
          // console.log('每一项~~~~~~``',m[item]);
          m[item].valuedata.map(subItem => {
            if (subItem != null) {
              // console.log('不为0的那一项是',subItem);
              chartNewData.push(subItem);
            }
          });
          // console.log('最小的chartNewData = []~~~~~~~~~~·',chartNewData);
          charMin.value = Math.min(...chartNewData);

          // minVal.value = Math.floor(charMin.value)
          minVal.value = parseFloat(charMin.value) - parseFloat(0.3);
          // console.log('最小值是',minVal.value);
        });
      } else {
        m.map(item => {
          if (item.zpIndexId == 1) {
            // console.log('存在30A指数拉',Math.max(...item.valuedata));

            // Amin.value = Math.min(...item.valuedata)
            Amin.value = parseFloat(Math.min(...item.valuedata)) - parseFloat(0.04);
          } else {
            chartNewData = [];
            for (let i in item.valuedata) {
              if (item.valuedata[i] != null) {
                // console.log();
                chartNewData.push(item.valuedata[i]);
              }
            }
            // console.log(Math.min(chartNewData));
            chartMinArr.value.push(Math.min(...chartNewData));
            charMin.value = Math.min(...chartMinArr.value);
            // console.log('最小',charMin.value);
            // minVal.value = Math.floor(charMin.value)
            minVal.value = parseFloat(Math.floor(charMin.value)) - parseFloat(0.3);
            // })
          }
        });
      }
    };

    const getChart = () => {
      // const loading = ref(true)
      myChart = echarts.init(chart.value);
      // console.log('1111111111111111111111ggggggggggggg111111111111111111111111111111');

      myChart.showLoading({
        text: 'loading',
        color: '#8b8b8b',
        textColor: '#8b8b8b',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      });
      let seriesData = []; // 用于存储所有曲线数据
      let hasZpIndexId1 = ref(false); // 用于标记是否存在zpIndexId为1的项
      props.chartArray.forEach(list => {
        if (list.zpIndexId == 1) {
          hasZpIndexId1.value = true;
        }
      });
      props.chartArray.map(list => {
        // 主坐标轴数据
        if (props.chartArray.length == 1) {
          // 只有一条数据的时候

          seriesData.push({
            name: list.indexItem,
            type: 'line',
            data: list.valuedata
          });
        } else {
          if (list.zpIndexId == 1) {
            // 是30A指数
            seriesData.push({
              name: list.indexItem,
              type: 'line',
              data: list.valuedata,
              yAxisIndex: 1 // 指定用第二个y轴
            });
          } else {
            // 不存在30A指数
            seriesData.push({
              name: list.indexItem,
              type: 'line',
              data: list.valuedata
            });
          }
        }

        // console.log('A的值是啊啊啊啊啊啊啊啊啊',Adata.value);
      });
      // console.log('2222222222222222222222222gggggggggggg2222222222222222222222222222222',seriesData);

      let option = {
        xAxis: {
          type: 'category',
          data: props.xDataArray,
          boundaryGap: false,
          axisLine: {
            onZero: false
          },
          axisLabel: {}
        },
        legend: {
          show: true,
          icon: "circle",
          //图例样式 
          bottom: 0
          // top:'5%'
        },

        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '1%',
          right: '4%',
          // bottom:'12%',
          top: 10,
          bottom: 70,
          containLabel: true
        },
        // dataZoom:{
        //   show:true,

        // },
        yAxis: [{
          type: 'value',
          // min:charMin.value,
          min: minVal.value,
          // max:charMax.value,
          max: maxVal.value,
          scale: true,
          axisLabel: {
            formatter: function (value, index) {
              var num = value.toFixed(2); // 控制小数点位数为6位
              var arr = num.split('.'); // 将整数部分和小数部分分别取出
              if (arr.length > 1) {
                // 如果有小数部分
                var decimal = arr[1];
                if (decimal.length < 2) {
                  // 如果小数位数小于2位
                  for (var i = decimal.length; i < 2; i++) {
                    decimal += '0'; // 补齐0
                  }
                }

                return arr[0] + '.' + decimal; // 拼接整数部分和小数部分
              } else {
                // 如果没有小数部分
                var result = arr[0];
                for (var i = 0; i < 2; i++) {
                  result += '0'; // 补齐0
                }

                return result;
              }
            }
          }
        }],
        series: seriesData
      };
      if (hasZpIndexId1.value) {
        // zpIndexId为1 ，存在，添加次坐标轴
        option.yAxis.push({
          type: 'value',
          min: Amin.value,
          max: Amax.value,
          scale: true,
          axisLabel: {
            formatter: function (value, index) {
              var num = value.toFixed(2); // 控制小数点位数为6位
              var arr = num.split('.'); // 将整数部分和小数部分分别取出
              if (arr.length > 1) {
                // 如果有小数部分
                var decimal = arr[1];
                if (decimal.length < 2) {
                  // 如果小数位数小于2位
                  for (var i = decimal.length; i < 2; i++) {
                    decimal += '0'; // 补齐0
                  }
                }

                return arr[0] + '.' + decimal; // 拼接整数部分和小数部分
              } else {
                // 如果没有小数部分
                var result = arr[0];
                for (var i = 0; i < 2; i++) {
                  result += '0'; // 补齐0
                }

                return result;
              }
            }
          }
        });
        option.yAxis[0].interval = (maxVal.value - minVal.value) / 5;
        option.yAxis[1].interval = (Amax.value - Amin.value) / 5;
      } else {
        //  如果不存在zpIndexId为1的项，则不添加次坐标轴配置
        option.yAxis[0].interval = (maxVal.value - minVal.value) / 5;
      }
      setTimeout(() => {
        // setOption前隐藏loading事件 
        myChart.hideLoading();
        myChart.setOption(option, true);
      }, 500);
    };
    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_withDirectives(_createElementVNode("div", {
        class: "char",
        ref_key: "chart",
        ref: chart,
        style: {
          "height": "300px",
          "width": "100%"
        }
      }, null, 512), [[_directive_loading, _ctx.loading]])]);
    };
  }
};