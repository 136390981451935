import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    // count: 0
    indexDetail:{}, // 不是30A指数的那个
    // indexDetailA:{}, // 是30A指数的
    checkId :'', // 30指数时，不显示最大回辙
    addIndexDetail:{}, // 点击添加对比指数后的那一个
  },
  mutations: {
    getIndexDetail(state,payload){
      state.indexDetail = payload
      // console.log('storedddddddddddddddd~~~~~~~~~~~~~~``',payload);
    },
    // getIndexDetailA(state,payload){
    //   state.indexDetailA = payload
    //   // console.log('storedddddddddddddddd~~~~~~~~~~~~~~``',payload);
    // },
    getIndexCheckId(state,checkId){
      state.checkId = checkId
      // console.log('传来的是',state.checkId);
    },
    getAddIndexDetail(state,addIndexDetail){
      state.addIndexDetail = addIndexDetail
      // console.log('addIndexDetail',state.addIndexDetail);
    }
  },
  actions: {
    
  },
  getters: {
    doubleCount(state) {
      return state.count * 2
    }
  },
  plugins: [createPersistedState({
    storage:window.sessionStorage  // 同localStorage相同，只是将vuex的所有值存储到sessionStorage中
})]

})
