import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import PCIndexDetail from '../components/indexList/indexDetail'
import mobileIndexDetail from '@/components/indexList/mobileIndexDetail'
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () => import('@/views/homePage.vue'),
        meta: {
            name: 'home'
        }
    },
    {
        path: '/service',
        component: () => import('@/views/servicePage.vue'),
        meta: {
            name: 'service'
        }
    },
    {
        path: '/about',
        component: () => import('@/views/aboutLxzp.vue'),
        meta: {
            name: 'about'
        }
    },
    {
        path: '/applyProbation',
        component: () => import('@/views/applyProbation.vue'),
        meta: {
            name: 'applyProbation'
        }
    },
    {
        path: '/csDownload',
        component: () => import('@/views/csDownload.vue'),
        meta: {
            name: 'csDownload'
        }
    },
    {
        path: '/law',
        component: () => import('@/views/lawDesc.vue'),
        meta: {
            name: 'law'
        }
    },
    {
        path: '/indexList',
        component: () => import('@/views/indexList.vue'),
        meta: {
            name: 'indexList'
        },



    },
    {
        path: '/indexDetail',
        name: 'indexDetail',
        // component:()=>import('@/components/indexList/indexDetail.vue'),
        component: window.innerWidth < 768 ? mobileIndexDetail : PCIndexDetail,
        meta: {
            isAuth: true // 参数true 表示必须从首页进入
        }

    }


]

const router = createRouter({
    // history:createWebHistory(),
    history: createWebHashHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    if (to.meta.isAuth) {
        // console.log('需要校验');
        // console.log('to',to.path);
        // console.log('from',from.path);
        if (to.path == '/indexDetail' && from.path == '/home') {

            sessionStorage.setItem('lastVisitedPage', to.path)
            next()


        } else if (to.path == '/indexDetail' && from.path == '/indexList') {
            sessionStorage.setItem('lastVisitedPage', to.path)
            next()
        } else {
            // console.log('刷新啦？');
            if (to.path == '/indexDetail' && from.path == '/' && sessionStorage.getItem('lastVisitedPage')) {
                next()
            } else if (to.path == '/indexDetail' && from.path == '/indexList' && sessionStorage.getItem('lastVisitedPage')) {
                next()
            } else {
                next('/home')
            }

        }
    } else {
        // console.log('不需要');
        next()
    }

})
export default router