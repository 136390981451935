import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from '@/interface/axiosInstance'
import store from './store'
import qs from 'qs'
import service from './interface/axiosInstance'
// import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
// import scroll from 'vue-seamless-scroll'
// import Carousel from "v3-carousel";
// router.beforeEach((to,from,next) => {
//     if(to.meta.isAuth){
//         // console.log('需要权限');
//         if(from.path !== '/home'  && from.path !=='/indexList'){
//             // console.log('不是从首页跳转的');
//             next({
//                 path:"/home"
//             })
//         }  
//         else {
//             next()
//         }
//     } else {
//         // console.log('不需要权限');

//         next()
//     }

// })
// console.log('获取路径',service);
const app = createApp(App)
app.use(router)
app.use(store)
// app.use(zhLocale)
// app.use(scroll)
// app.use(Carousel)
app.mount('#app')
app.config.globalProperties.$axios=axios; // 全局引用
app.config.globalProperties.$qs = qs;
// app.config.globalProperties.$scroll = scroll;
