import service from "./axiosInstance";
import qs from 'qs'

export const getIndexCarousel = () => {  // 走马灯数据
    return service.post('/web/home/zpIndexCarousel')
}


export const getIndexList = () => {  // 智评指数列表
    return service.post('/web/home/queryZpIndexNewList')
}


export const getIndex = () => {  // 智评指数detail里侧边导航栏
    return service.post('/web/home/zpIndex2')
}


export const getHistory = (data) => {  // 历史数据
    // return service.post('/home/indexHistory',{ zpIndexId})
    return service({
        url: '/web/home/indexHistory',
        method: 'post',
        data
    })
}

// /api/web/home/zpIndexById?id=2
export const getPdf = (data) => {  // 历史数据
    // return service.post('/home/indexHistory',{ zpIndexId})
    return service({
        url: `/web/home/zpIndexById?id=${data}`,
        method: 'post',
        // data
    })
}


export const getIndexDetailData = (data) => {  // 图表数据
    return service({
        url: "/web/home/zpIndexDetailData",
        method: 'post',
        data: qs.stringify(data)
    })
}


export const getIndexBehaveData = (data) => {  // 表现收益

    return service({
        url: `/web/home/indexExpressiveBenefit`,
        method: 'post',
        // data: qs.stringify(data)
        data: data
    })
}


export const getNewListDownload = () => {  // 智评下载

    return service({
        url: "/web/home/zpIndexNewListDownload",
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',

        }

    })
}

export const getHistoryDownload = (data) => {  // 下载
    return service({
        url: `/web/home/indexHistoryDownload?zpIndexId=${data}`,
        method: 'post',
        // data:qs.stringify(data),
        responseType: 'blob',
        // headers: {
        //     'Content-Type': 'application/json',
        // }       
    })
}


export const applyuserApplyAccount = (data) => {  // // 申请试用

    return service({
        // url:"/business/userApplyAccount/add",
        url: '/web/userApplyAccount/UserApplyRegister',
        method: 'post',
        data: qs.stringify(data)
    })
}

// 上传图片
export const uploadImg = (data) => {  // // 上传名片

    return service({
        // url:"/business/userApplyAccount/add",
        url: `/web/oss/uploadFile`,
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: data,
        // processData: false,
        // contentType: false,
    })
}


export const sendCodeSms = (data) => {  // 获取验证码
    return service({
        // url:"/business/userApplyAccount/add",
        // url:`/web/register/sendSms?phone=${data}`,
        // url:`/web/register/sendSmsRemember?phone=${data}`,
        url: `/web/register/sendSms?phone=${data}&type=3`,
        // /register/sendSmsRemember
        method: 'post',
        // data:qs.stringify(data)
    })
}

// 下载

export const downZD = (data) => {  // 
    // /web/home/indexExpressiveBenefit
    // https://ieva.lianxinzhiping.com/api/web/businessUser/queryDownUrl?loginName=15034013998&password=lxzp987
    return service({
        url: `/web/businessUser/queryDownUrl?loginName=${data.loginName}&password=${data.password}`,
        method: 'get',
        // data:qs.stringify(data)
    })
}
